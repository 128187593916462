<template>
  <!-- Add Contract Modal -->
  <b-modal
    id="manualDriverRegistration"
    lazy
    no-fade
    size="lg"
    :title="$t('modalSignup.title')"
    hide-footer
    @show="handleOpen"
  >
    <ValidationObserver
      v-slot="{ valid }"
      ref="observer"
      tag="form"
      @submit.prevent="doCreateContract()"
    >
      <b-form>
        <b-form-row class="mb-2">
          <b-form-group
            id="driver-name-gp"
            :label="`${config.name.label} *`"
            label-for="driver-name"
            autocomplete="off"
            class="col-md-8 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|signupNameValid'"
              :name="config.name.label"
            >
              <b-form-input
                id="driver-name"
                v-model.trim="newDriver.name"
                :disabled="block_ui"
                required
                type="text"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            id="driver-document-gp"
            :label="`${config.document.label} *`"
            label-for="driver-document"
            autocomplete="off"
            class="col-md-4 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|cpfValid'"
              :name="config.document.label"
            >
              <the-mask
                id="driver-document"
                v-model.trim="newDriver.document"
                type="text"
                :disabled="block_ui"
                autocomplete="off"
                required
                class="form-control"
                :mask="config.document.mask"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>
        </b-form-row>

        <b-form-row class="mb-2">
          <b-form-group
            id="driver-email-gp"
            :label="`${config.email.label} *`"
            label-for="driver-email"
            autocomplete="off"
            class="col-md-5 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|emailValid'"
              :name="config.email.label"
            >
              <b-form-input
                id="driver-email"
                v-model.trim="newDriver.email"
                :disabled="block_ui"
                autocomplete="off"
                required
                type="email"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            id="driver-phone-gp"
            :label="`${config.phone.label} *`"
            label-for="driver-phone"
            autocomplete="off"
            class="col-md-3 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|signupPhoneValid'"
              :name="config.phone.label"
            >
              <the-mask
                id="driver-phone"
                v-model.trim="newDriver.phone"
                type="tel"
                :disabled="block_ui"
                autocomplete="off"
                required
                class="form-control"
                :mask="config.phone.mask"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            id="driver-work-city-gp"
            :label="`${config.workCity.label} *`"
            label-for="driver-workCity"
            class="col-md-4 col-sm-12"
          >
            <b-form-select
              id="driver-workCity"
              v-model="workCity"
              :text-field="newDriver.workCity"
              :options="workCityList"
              :disabled="block_ui"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row class="mb-2">
          <b-form-group
            id="driver-emergency-phone-gp"
            :label="`${config.emergencyPhone.label} *`"
            label-for="driver-emergency-phone"
            autocomplete="off"
            class="col-md-3 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|signupPhoneValid'"
              :name="config.emergencyPhone.label"
            >
              <the-mask
                id="driver-emergency-phone"
                v-model.trim="newDriver.emergencyPhone"
                type="tel"
                :disabled="block_ui"
                autocomplete="off"
                required
                class="form-control"
                :mask="config.phone.mask"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            id="driver-emergency-name-gp"
            :label="`${config.emergencyName.label} *`"
            label-for="driver-emergency-name"
            autocomplete="off"
            class="col-md-9 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required|signupNameValid'"
              :name="config.emergencyName.label"
            >
              <b-form-input
                id="driver-emergency-name"
                v-model.trim="newDriver.emergencyName"
                :disabled="block_ui"
                autocomplete="off"
                required
                type="email"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>
        </b-form-row>

        <b-form-row class="mb-2">
          <b-form-group
            id="driver-kyc-gp"
            :label="`${config.kyc.label} *`"
            label-for="driver-kyc"
            class="col-md-4 col-sm-12"
          >
            <ValidationProvider
              v-slot="{errors, valid}"
              :rules="'required'"
              :name="config.kyc.name"
            >
              <b-form-checkbox-group
                id="driver-kyc"
                v-model="kycCarUse.selectedValue"
                :options="kycCarUse.items"
                name="flavour-1"
                :disabled="block_ui"
              />
              <span class="error">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-form-group>
        </b-form-row>

        <b-col cols="8" class="pl-0">
          <h5 class="mt-1 mb-3 ml-0">
            {{ $t('drivers.texts.addressTitle') }}
          </h5>
        </b-col>

        <b-form-row class="mb-2">
          <b-form-group
            class="col-md-12 mb-2"
          >
            <kovi-address
              :google-input="configAddress.googleInput"
              :address-name="configAddress.addressName"
              :address-number="configAddress.addressNumber"
              :address-no-number="configAddress.addressNoNumber"
              :address-complement="configAddress.addressComplement"
              :address-neighborhood="configAddress.addressNeighborhood"
              :address-city="configAddress.addressCity"
              :address-state="configAddress.addressState"
              :address-country="configAddress.addressCountry"
              :address-postal-code="configAddress.addressPostalCode"
              :address-lat-lng="configAddress.addressLatLng"
              :garage-address-name="configAddress.garageAddressName"
              :garage-address-number="configAddress.garageAddressNumber"
              :garage-address-complement="configAddress.garageAddressComplement"
              :garage-address-neighborhood="configAddress.garageAddressNeighborhood"
              :garage-address-city="configAddress.garageAddressCity"
              :garage-address-state="configAddress.garageAddressState"
              :garage-address-country="configAddress.garageAddressCountry"
              :garage-address-postal-code="configAddress.garageAddressPostalCode"
              :message-required-address-fields="configAddress.messageRequiredAddressFields"
              :message-required-garage-fields="configAddress.messageRequiredGarageFields"
              :message-invalid-address="configAddress.messageInvalidAddress"
              :show-garage-address="true"
              @new-address="changeAddress($event)"
            />
          </b-form-group>
        </b-form-row>

        <hr v-if="!hasErrors">

        <b-col v-if="hasErrors" cols="8" class="pl-0 error">
          <h5 class="mt-1 mb-3 ml-0">
            {{ $t('modalSignup.errors.title') }}
          </h5>
          <p v-for="(error, index) in errors" :key="index" class="mt-1 mb-2 ml-0">
            {{ $t(`modalSignup.errors.${error}`) }}
          </p>
        </b-col>

        <b-form-row>
          <b-col cols="12" class="text-right mt-1 mb-2">
            <b-button :disabled="!isValidToCreate || block_ui" variant="success" title="$t('modalSignup.buttons.save')"
                      @click.prevent="doCreateContract"
            >
              {{ $t('modalSignup.buttons.save') }}
              <b-spinner v-if="saving" style="width: 1rem; height: 1rem;" class="ml-1" />
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { isMexico } from '@utils/helper-region';
import KoviAddress from '@components/custom/address/kovi-address';
import { WORK_CITY_BR } from '@enums/signup';
import CREATE_DRIVER from '@graphql/driver/mutations/create-signup.gql';
import { mapGetters } from 'vuex';

export default {
  name: 'ManualDriverRegistration',
  components: {
    KoviAddress
  },
  props: {},
  data() {
    return {
      errors: [],
      saving: false,
      newDriver: {
        name: '',
        document: '',
        email: '',
        phone: '',
        licenseNumber: '',
        emergencyPhone: '',
        emergencyName: '',
        fiscalNumber: ''
      },
      workCity: 'SAO_PAULO',
      address: {
        postal_code: '',
        street_name: '',
        street_number: '',
        street_no_number: false,
        street_details: '',
        neighborhood: '',
        city: '',
        state: '',
        country: 'BR'
      },
      latLng: '',
      garageIsAnotherAddress: false,
      garage_address: {
        postal_code: '',
        street_name: '',
        street_number: '',
        street_details: '',
        neighborhood: '',
        city: '',
        state: ''
      },
      validAddress: false,
      config: {
        name: {
          label: this.$t('modalSignup.inputs.name')
        },
        document: {
          label: this.$t('modalSignup.inputs.document'),
          mask: "###.###.###-##"
        },
        email: {
          label: this.$t('modalSignup.inputs.email')
        },
        phone: {
          label: this.$t('modalSignup.inputs.phone'),
          mask: "(##) #####-####"
        },
        fiscalNumber: {
          label: this.$t('modalSignup.inputs.fiscalNumber')
        },
        workCity: {
          label: this.$t('modalSignup.inputs.workCity')
        },
        kyc: {
          label: this.$t('modalSignup.kyc.title'),
          name: "KYC"
        },
        emergencyPhone: {
          label: this.$t('modalSignup.inputs.emergencyPhone'),
        },
        emergencyName: {
          label: this.$t('modalSignup.inputs.emergencyName'),
        },
        garageRadio: {
          label: this.$t('modalSignup.garageAnotherAddress.title'),
          options: {
            yes: this.$t('modalSignup.garageAnotherAddress.options.yes'),
            no: this.$t('modalSignup.garageAnotherAddress.options.no')
          }
        }
      },
      kycCarUse: {
        selectedValue: [],
        items:[
          {
            value: 'isAppDriver',
            text: 'Trabalho - motorista de app'
          },
          {
            value: 'isOtherWork',
            text: 'Trabalho - outros'
          },
          {
            value: 'isPersonalUsage',
            text: 'Uso pessoal'
          }
        ]
      },
      block_ui: false,
    };
  },
  computed: {
    ...mapGetters({
      country: 'user/country'
    }),
    configAddress () {
      let countryListOptions = [{ text: 'Brasil', value: 'BR' }]
      if (isMexico()) countryListOptions = [{ text: 'México', value: 'MX' }]

      const {
        address_street_name,
        address_street_number,
        address_street_no_number,
        address_street_details,
        address_neighborhood,
        address_city,
        address_state,
        address_postal_code,
      } = this.address

      const {
        street_name: g_street_namestreet_name,
        street_number: g_street_number,
        street_details: g_street_details,
        neighborhood: g_neighborhood,
        city: g_city,
        state: g_state,
        postal_code: g_postal_code
      } = this.garage_address

      const validPostalCodeLength = isMexico() ? 5 : 8
      const noNumber = (!address_street_number || address_street_no_number)

      return {
        googleInput: {
          color: '000000',
          borderWidth: '1px',
          borderColor: '228, 231, 234',
          borderRadius: '0.25rem',
          textAlign: 'center',
          complete: 'off',
          paddingTop: '8',
          paddingLeft: '10',
          paddingRight: '20',
          paddingBottom: '8',
          locale: this.country,
          placeholder: this.$t('drivers.components.koviAddress.google.placeholder'),
          title: this.$t('drivers.components.koviAddress.texts.addressTitle'),
          garageCheckTitle: this.$t('drivers.components.koviAddress.texts.garageAddressCheck'),
          garageBlockTitle: this.$t('drivers.components.koviAddress.texts.garageBlockTitle'),
          garageTitle: this.$t('drivers.components.koviAddress.texts.garageTitle')
        },
        addressName: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.publicPlace'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_street_name,
          'type': 'text'
        },
        addressNumber: {
          'required': true,
          'editable': true,
          'title': this.$t('drivers.labels.number'),
          'placeholder': 'Número',
          'showPlaceholder': false,
          'value': address_street_number,
          'type': 'text',
          'mask': '#####'
        },
        addressNoNumber: {
          'required': false,
          'editable': true,
          'title': this.$t('drivers.texts.noNumber'),
          'value': noNumber
        },
        addressComplement: {
          'required': false,
          'editable': true,
          'title': this.$t('drivers.labels.complement'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_street_details,
          'type': 'text'
        },
        addressNeighborhood: {
          'required': true,
          'editable': true,
          'title': this.$t('drivers.labels.district'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_neighborhood,
          'type': 'text'
        },
        addressCity: {
          'required': false,
          'editable': false,
          'title': this.$t('drivers.labels.city'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_city,
          'type': 'text'
        },
        addressState: {
          'required': false,
          'editable': false,
          'title': this.$t('drivers.labels.state'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_state,
          'type': 'text'
        },
        addressCountry: {
          'required': false,
          'editable': false,
          'title': this.$t('drivers.labels.country'),
          'placeholder': '',
          'showPlaceholder': false,
          'options': countryListOptions,
          'value': countryListOptions[0].value,
          'type': 'text'
        },
        addressPostalCode: {
          'required': true,
          'editable': true,
          'title': this.$t('drivers.labels.cep'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': address_postal_code,
          'type': 'text',
          'mask': this.$t('drivers.masks.postalCode'),
          'validLength': validPostalCodeLength
        },
        addressLatLng: {
          'required': true,
          'value': this.latLng,
          'type': 'text'
        },
        garageAddressName: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.publicPlace'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_street_namestreet_name,
          'type': 'text'
        },
        garageAddressNumber: {
          'required': false,
          'editable': true,
          'title': this.$t('drivers.labels.number'),
          'placeholder': 'Número',
          'showPlaceholder': false,
          'value': g_street_number,
          'type': 'text',
          'mask': '#####'
        },
        garageAddressComplement: {
          'required': false,
          'editable': true,
          'title': this.$t('drivers.labels.complement'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_street_details,
          'type': 'text'
        },
        garageAddressNeighborhood: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.district'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_neighborhood,
          'type': 'text'
        },
        garageAddressCity: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.city'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_city,
          'type': 'text'
        },
        garageAddressState: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.state'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_state,
          'type': 'text'
        },
        garageAddressCountry: {
          'required': true,
          'editable': false,
          'title': this.$t('drivers.labels.country'),
          'placeholder': '',
          'showPlaceholder': false,
          'options': countryListOptions,
          'value': countryListOptions[0].value,
          'type': 'text'
        },
        garageAddressPostalCode: {
          'required': true,
          'editable': true,
          'title': this.$t('drivers.labels.cep'),
          'placeholder': '',
          'showPlaceholder': false,
          'value': g_postal_code,
          'type': 'text',
          'mask': this.$t('drivers.masks.postalCode'),
          'validLength': validPostalCodeLength
        },
        messageRequiredAddressFields: this.$t('drivers.components.koviAddress.errors.messageRequiredAddressFields'),
        messageRequiredGarageFields: this.$t('drivers.components.koviAddress.errors.messageRequiredGarageFields'),
        messageInvalidAddress: this.$t('drivers.components.koviAddress.errors.messageInvalidAddress')
      }
    },
    hasErrors () {
      return this.errors.length
    },
    workCityList () {
      return WORK_CITY_BR
    },
    hasAddress () {
      return (this.address.street_name !== '')
    },
    isValidToCreate() {
      const checks = [
        ['name', 'document', 'email', 'phone', 'emergencyPhone', 'emergencyName']
          .every(field => !!this.newDriver[field]),

        this.validAddress,

        this.kycCarUse.selectedValue.length > 0
      ];

      return checks.every(Boolean);
    },
    createCarUsage () {
      const _ = this
      return this.kycCarUse.items.map((item) => {
        const selected = _.kycCarUse.selectedValue.includes(item.value)
        return {
          key: item.value,
          value: selected
        }
      })
    }
  },
  watch: {
    garageIsAnotherAddress (newValue) {
      if (!newValue) {
        this.garage_address = {
          postal_code: '',
          street_name: '',
          street_number: '',
          street_details: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      }
    }
  },
  methods: {
    handleOpen() {
      this.block_ui = false;
      this.newDriver = {}
      this.mountList()
    },
    mountList() {
      this.resetFields()
    },
    resetFields () {
      this.newDriver = {
        name: '',
        document: '',
        email: '',
        phone: '',
        licenseNumber: '',
        emergencyPhone: '',
        emergencyName: '',
        fiscalNumber: ''
      }
      this.workCity = 'SAO_PAULO'
      this.address = {
        postal_code: '',
        street_name: '',
        street_number: '',
        street_no_number: false,
        street_details: '',
        neighborhood: '',
        city: '',
        state: '',
        country: 'BR'
      }
      this.garageIsAnotherAddress = false
      this.garage_address = {
        postal_code: '',
        street_name: '',
        street_number: '',
        street_details: '',
        neighborhood: '',
        city: '',
        state: ''
      }
      this.kycCarUse.selectedValue = []
      this.errors = []
    },
    changeAddress($event) {
      const { address: newAddress, addressIsValid, garage, garageIsValid, hasGarage } = $event

      this.validAddress = !!(addressIsValid && garageIsValid)

      this.address.street_name = newAddress.name
      this.address.street_number = newAddress.number
      this.address.street_no_number = !newAddress.number
      this.address.street_details = newAddress.complement
      this.address.neighborhood = newAddress.neighborhood
      this.address.city = newAddress.city
      this.address.state = newAddress.state
      this.address.postal_code = newAddress.postalCode
      this.address.country = newAddress.country

      this.latLng = newAddress.latLng

      this.garageIsAnotherAddress = hasGarage

      this.garage_address.street_name = garage.name
      this.garage_address.street_number = garage.number
      this.garage_address.street_details = garage.complement
      this.garage_address.neighborhood = garage.neighborhood
      this.garage_address.city = garage.city
      this.garage_address.state = garage.state
      this.garage_address.postal_code = garage.postalCode
    },
    async doCreateContract() {
      if (!this.isValidToCreate) return
      this.errors = []
      const formatedData = {
        name: this.newDriver.name,
        cpf: this.newDriver.document,
        email: this.newDriver.email,
        phone_number: `55${this.newDriver.phone}`,
        license_number: this.newDriver.licenseNumber,
        emergency_1_name: this.newDriver.emergencyName,
        emergency_1_phone_number: this.newDriver.emergencyPhone,
        fiscal_number: this.newDriver.fiscalNumber,
        work_city: this.workCity,
        address_city: this.address.city,
        address_country: this.address.country,
        address_latlong: this.latLng,
        address_neighborhood: this.address.neighborhood,
        address_postal_code: this.address.postal_code,
        address_state: this.address.state,
        address_street_details: this.address.street_details,
        address_street_name: this.address.street_name,
        address_street_number: this.address.street_number,
        garage_address: {
          city: this.garage_address.city,
          neighborhood: this.garage_address.neighborhood,
          postal_code: this.garage_address.postal_code,
          state: this.garage_address.state,
          street_details: this.garage_address.street_details,
          street_name: this.garage_address.street_name,
          street_number: this.garage_address.street_number
        },
        car_usage_answers: this.createCarUsage
      }
      const _ = this
      this.saving = true

      this.block_ui = true;
      this.$apollo
        .mutate({
          mutation: CREATE_DRIVER,
          variables: {
            ...formatedData
          },
        })
        .then(({ data }) => {
          const driverId = data.createDriver.id

          this.$root.$emit('bv::hide::modal', 'modalDriverKoviProprio');

          // Identify user on segment
          const carUsageValues = {
            isAppDriver: false,
            isOtherWork: false,
            isPersonalUsage: false
          }
          this.createCarUsage.forEach(item => {
            carUsageValues[item.key] = item.value
          })
          window.analytics.track('KYC', { kyc: {
            customerReferenceId: driverId,
            kind: 'CAR_USAGE',
            data: carUsageValues
          }})

          this.$swal({
            type: 'success',
            title: _.$t('modalSignup.swal.success.title'),
            text: _.$t('modalSignup.swal.success.text'),
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            _.$router.push({ path: `/drivers/profile/${driverId}` })
          })
        })
        .catch((error) => {
          this.saving = false
          this.errors = error.graphQLErrors.map(item => item.code)

          this.$swal({
            type: 'error',
            title: _.$t('modalSignup.swal.error.title'),
            text: _.$t('modalSignup.swal.error.text'),
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.block_ui = false;
          });
        });
    }
  }
};
</script>

<style>
.error {
  color: #f86c6b;
  position: relative;
  top: 5px;
}
</style>
